import React, { useState } from "react";
import Layout from "../components/layout";
import CcpaForm from "../components/ccpaform";
import { SEO } from "../components/seo";
import { graphql } from "gatsby";
import * as ccpaStyles from "../styles/components/ccpa.module.scss";
import parse from "html-react-parser";
import { getPageByLanguage } from "../hooks/get-page-by-language";
import useCookie from "../hooks/use-cookie";
import { getBrowserLanguage } from "../hooks/get-language";
import { getHeaderAndFooter } from "../hooks/get-header-and-footer";

const Ccpa = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let foundCcpa = getPageByLanguage(
    data.allContentstackCcpa.nodes,
    false,
    cookie
  );
  let foundFormDisplayText = getPageByLanguage(
    data.allContentstackFormDisplayText.nodes,
    false,
    cookie
  );
  let relationshipToCompanyMultiselect =
    foundFormDisplayText.relationship_to_company_multiselect.map(
      (relationship) => {
        return { name: relationship, id: relationship };
      }
    );
  let typeOfRequestMultiselect =
    foundFormDisplayText.type_of_request_multiselect.map((type) => {
      return { name: type, id: type };
    });

  const isBrowser = typeof window !== "undefined";
  if (!foundCcpa && isBrowser) {
    navigate("/404/");
  }

  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <section className={ccpaStyles.headingSection}>
        {foundCcpa.display_title && (
          <h1 className={ccpaStyles.mainTitle}>{foundCcpa.display_title}</h1>
        )}
        {foundCcpa.about_form && (
          <div className={ccpaStyles.aboutForm}>
            {parse(foundCcpa.about_form)}
          </div>
        )}
      </section>
      <CcpaForm
        formDisplayText={foundFormDisplayText}
        relationshipToCompanyMultiselect={relationshipToCompanyMultiselect}
        typeOfRequestMultiselect={typeOfRequestMultiselect}
      />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackCcpa {
      nodes {
        locale
        display_title
        about_form
      }
    }
    allContentstackFormDisplayText {
      nodes {
        address_label
        address_placeholder
        all_placeholder
        confirm_email_label
        confirm_email_placeholder
        country_label
        email_label
        email_placeholder
        first_name_label
        first_name_placeholder
        last_name_label
        last_name_placeholder
        locale
        message_label
        phone_number_label
        phone_number_placeholder
        region_label
        relationship_to_company_label
        relationship_to_company_multiselect
        required
        submit_label
        type_of_request_label
        type_of_request_multiselect
      }
    }
  }
`;
export default Ccpa;

export const Head = () => <SEO title="Elevate Outdoor Collective | CCPA" />;
