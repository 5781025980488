// extracted by mini-css-extract-plugin
export var aboutForm = "ccpa-module--about-form--dc010";
export var btnDark = "ccpa-module--btn-dark--aa3c6";
export var btnLight = "ccpa-module--btn-light--bf6a6";
export var buttonDefault = "ccpa-module--button-default--3a35e";
export var buttonLarge = "ccpa-module--button-large--0c86c";
export var buttonSmall = "ccpa-module--button-small--c52ac";
export var ctaLarge = "ccpa-module--cta-large--7e659";
export var ctaSmall = "ccpa-module--cta-small--48f09";
export var headingSection = "ccpa-module--heading-section--59798";
export var highlight = "ccpa-module--highlight--819be";
export var input = "ccpa-module--input--772fa";
export var labelLarge = "ccpa-module--label-large--58f47";
export var labelMedium = "ccpa-module--label-medium--8174c";
export var languages = "ccpa-module--languages--6c8e1";
export var link = "ccpa-module--link--417c3";
export var mainTitle = "ccpa-module--main-title--e2be0";
export var optionContainer = "ccpa-module--optionContainer--9a965";
export var optionListContainer = "ccpa-module--optionListContainer--71d3d";
export var pLarge = "ccpa-module--p-large--f742f";
export var pLargeBold = "ccpa-module--p-large-bold--13291";
export var pMedium = "ccpa-module--p-medium--cb012";
export var pSmall = "ccpa-module--p-small--b21c4";
export var searchWrapper = "ccpa-module--search-wrapper--b9d6a";
export var singleChip = "ccpa-module--singleChip--61ed6";
export var slickNext = "ccpa-module--slick-next--67927";
export var slickPrev = "ccpa-module--slick-prev--d89e5";
export var slickSlide = "ccpa-module--slick-slide--6a884";
export var titleLarge = "ccpa-module--title-large--e3b34";
export var titleMedium = "ccpa-module--title-medium--c7804";
export var titleSmall = "ccpa-module--title-small--4fb39";
export var titleXl = "ccpa-module--title-xl--5bd37";